import React, { memo, useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Link,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import styles from './PersonActivitiesTable.module.css';
import useFetchPersonActivities from '../../../services/api/workspace/people/hooks/useFetchPersonActivities';
import { useUserSettingsStore } from '../../../stores';
import { PaginationParams } from '../../../services/api/types';
import { DetailsRetrievalStatus } from '../../../services/api/workspace/types';
import { formatDate, defaultRowsPerPage, rowsPerPageOptions } from './PersonActivitiesTable.utils';
import DetailsTableItemsNotFound from '../DetailsTableItemsNotFound';
import { truncateText } from '../../../utils/string/string';

interface PersonActivitiesTableProps {
  personExtractId: string;
  retrievalStatus: DetailsRetrievalStatus;
}

const PersonActivitiesTable: React.FC<PersonActivitiesTableProps> = ({
  personExtractId,
  retrievalStatus,
}) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);

  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    page: 1,
    size: defaultRowsPerPage,
  });

  const {
    data: { totalCount, data: personActivities },
    refetch: refetchPersonActivities,
  } = useFetchPersonActivities(selectedFolderId, personExtractId, paginationParams, false);

  useEffect(() => {
    refetchPersonActivities();
  }, [paginationParams, refetchPersonActivities]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationParams((prevParams) => ({ ...prevParams, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      page: 1,
      size: +event.target.value,
    }));
  };

  const showTableLoader = retrievalStatus === 'in progress' && !personActivities.length;
  const showNoRecords =
    (retrievalStatus === 'done' || retrievalStatus === 'partially_done') &&
    !personActivities.length;

  return (
    <Paper className={styles.container}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Content Summary</TableCell>
              <TableCell>Content type</TableCell>
              <TableCell>Platform</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Reactions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showTableLoader && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {showNoRecords && <DetailsTableItemsNotFound title="No Activities Found" />}

            {!showTableLoader &&
              !showNoRecords &&
              personActivities.map((activity) => (
                <TableRow key={activity.id}>
                  <TableCell>
                    <Link target="_blank" href={activity.url}>
                      {truncateText(activity.content_summary, 120)}
                    </Link>
                  </TableCell>
                  <TableCell>{activity.content_type}</TableCell>
                  <TableCell>{activity.platform}</TableCell>
                  <TableCell>{formatDate(activity.source_created_time)}</TableCell>
                  <TableCell>
                    <Typography variant="body2" textAlign="center" fontWeight={700}>
                      {activity.reactions_count}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={+totalCount || 0}
        rowsPerPage={paginationParams?.size || defaultRowsPerPage}
        page={paginationParams.page ? paginationParams.page - 1 : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default memo(PersonActivitiesTable);
