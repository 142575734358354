import React, { useMemo, useState } from 'react';
import {
  Typography,
  Toolbar,
  Icon,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  IconButton,
  TablePagination,
  Collapse,
  Grid,
} from '@mui/material';
import Groups3Icon from '@mui/icons-material/Groups3';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './PeopleTable.module.css';
import { PersonResult } from '../../../services/api/search/types';
import { flattenPeopleExtracts, peopleTableConfig } from './PeopleTable.utils';
import { useTableUtils, useToggle } from '../../../hooks';
import PickFolderModal from '../PickFolderModal/PickFolderModal';

import AddToWorkspaceButton from '../AddToWorkspaceButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import { useManagePersonFolders } from '../../../services/api/workspace/people/hooks';
import { Folder } from '../../../services/api/workspace/types';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { generateLinkToPersonLinkedin } from '../../../utils/string/string';

interface PeopleTableProps {
  data: PersonResult[];
}

const {
  defaultRowsPerPage,
  exportFileName,
  exportHeaders,
  getExportRows,
  filterByKeyword,
  rowsPerPageOptions,
} = peopleTableConfig;

const PeopleTable: React.FC<PeopleTableProps> = ({ data }) => {
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [initialSelectedFoldersIds, setInitialSelectedFoldersIds] = useState<string[]>([]);

  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);

  const { mutate: managePersonFolders } = useManagePersonFolders();
  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const flattenedPeople = flattenPeopleExtracts(data);

  const peopleIdsInFolders = useMemo(
    () =>
      folders.reduce((acc: string[], item: Folder) => {
        return [...acc, ...item.people_extracts_ids];
      }, []),
    [folders],
  );

  const {
    data: paginatedPeople,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    expandedRows,
    toggleRowExpansion,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
  } = useTableUtils(flattenedPeople, defaultRowsPerPage, filterByKeyword);

  const handleSave = (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => {
    if (selectedPersonId) {
      managePersonFolders({
        foldersIdsToAdd,
        foldersIdsToRemove,
        personExtractId: selectedPersonId,
      });
    }
  };

  const handleToggleFolderButton = (personId: string) => {
    const personsFoldersIds = folders.reduce((acc: string[], item: Folder) => {
      if (item.people_extracts_ids.includes(personId)) return [...acc, item.id];
      return acc;
    }, []);

    setSelectedPersonId(personId);
    setInitialSelectedFoldersIds(personsFoldersIds);
    togglePickFolderModalOpen();
  };

  const exportRows = getExportRows(flattenedPeople);

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <Toolbar>
        <Icon className={styles.titleIcon} color="inherit" aria-label="menu">
          <Groups3Icon />
        </Icon>
        <Typography className={styles.title} variant="h6" component="div">
          Trending People
        </Typography>
      </Toolbar>
      <Box className={styles.content}>
        <TableToolbar
          onFilter={onFilter}
          onExportCSV={handleExportCSV}
          onExportExcel={handleExportExcel}
        />

        {foldersFetched && (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <SortableTableCell
                      columnKey="full_name"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Name
                    </SortableTableCell>
                    <TableCell>Actions</TableCell>
                    <SortableTableCell
                      columnKey="reasoning"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Position
                    </SortableTableCell>
                    <TableCell align="center">Social Media</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedPeople.map((person) => (
                    <React.Fragment key={person.id}>
                      <TableRow>
                        <TableCell>
                          <IconButton onClick={() => toggleRowExpansion(person.id)}>
                            {expandedRows.has(person.id) ? <RemoveIcon /> : <AddIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight={600} variant="body2">
                            {person.full_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box className={styles.actions}>
                            <AddToWorkspaceButton
                              onClick={() => handleToggleFolderButton(person.id)}
                              defaultValue={peopleIdsInFolders.includes(person.id)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>{person.reasoning}</TableCell>
                        <TableCell align="center">
                          <Link
                            href={generateLinkToPersonLinkedin(person.full_name)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon
                              fontSize="large"
                              className={styles.linkedInIcon}
                              color="primary"
                            />
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          className={`${styles.collapseContainer} ${expandedRows.has(person.id) ? styles.expanded : ''}`}
                        >
                          <Collapse in={expandedRows.has(person.id)} timeout="auto" unmountOnExit>
                            <Box padding={4}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography variant="h6" gutterBottom>
                                    Additional Details
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="textSecondary">
                                    Core Interests:
                                  </Typography>
                                  <Typography variant="body1">{person.core_interests}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="textSecondary">
                                    Grade:
                                  </Typography>
                                  <Typography variant="body1">{person.grade}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="textSecondary">
                                    Job Title:
                                  </Typography>
                                  <Typography variant="body1">{person.job_title}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="textSecondary">
                                    Spoke at Conference:
                                  </Typography>
                                  <Typography variant="body1">
                                    {person.spoke_at_conference ? 'Yes' : 'No'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={flattenedPeople.length}
              rowsPerPage={rowsPerPage}
              page={page}
              defaultValue={defaultRowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {pickFolderModalOpen && (
              <PickFolderModal
                open={pickFolderModalOpen}
                onClose={togglePickFolderModalOpen}
                onSave={handleSave}
                folders={folders}
                initialSelectedFoldersIds={initialSelectedFoldersIds}
              />
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default PeopleTable;
