import { useQuery } from '@tanstack/react-query';
import { fetchSponsorNotes } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchSponsorNotes = (payload: PaginationParams & { sponsor_extract_id: string }) => {
  return useQuery({
    queryKey: ['notes', payload.sponsor_extract_id],
    queryFn: () => fetchSponsorNotes(payload),
    initialData: [],
  });
};

export default useFetchSponsorNotes;
