export const defaultStep = 1;
export const totalSteps = 3;

export const getStepInfo = (step: number, isFirstProjectCreation: boolean) => {
  const infoByStep: { [key: number]: { title: string; description: string } } = {
    1: {
      title: isFirstProjectCreation ? 'Name your first event.' : 'Name your event.',
      description:
        'You’re just a few steps away from building a powerful agenda. Let’s start by naming your event:',
    },
    2: {
      title: 'What’s the main theme of your event?',
      description:
        'Choose a theme or focus area for your event. This will help us kickstart your search for the most relevant topics, speakers, and sponsors.',
    },
    3: {
      title: 'Help us understand your vision',
      description:
        'Tell us as much as you can about your event—its purpose, audience, goals, or any other details.',
    },
  };

  return infoByStep[step];
};
