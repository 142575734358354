import React, { memo, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import SearchSummary from '../SearchSummary/SearchSummary';
import SearchResults from '../SearchResults';
import { useFetchFullSearch } from '../../../services/api/search/hooks';
import DashboardSkeleton from '../DashboardSkeleton/DashboardSkeleton';
import { useUserSettingsStore } from '../../../stores';

interface SearchDetailsProps {
  searchId: string;
}

const SearchDetails: React.FC<SearchDetailsProps> = ({ searchId }) => {
  const setSelectedSearchId = useUserSettingsStore((store) => store.setSelectedSearchId);
  const { data: searchDetails, isLoading } = useFetchFullSearch(searchId);

  useEffect(() => {
    if (!isLoading && searchDetails) {
      setSelectedSearchId(searchDetails.search_id);
    }
  }, [searchDetails, isLoading, setSelectedSearchId]);

  if (isLoading) return <DashboardSkeleton />;
  if (!searchDetails) return <Typography>No search details available.</Typography>;

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <SearchSummary searchDetails={searchDetails} />
        </Grid>
        <Grid item xs={12} md={12}>
          {<SearchResults searchDetails={searchDetails} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SearchDetails);
