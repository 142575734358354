import { defaultRowsPerPage, defaultRowsPerPageOptions } from '../../constants/tableConstants';

type TableConfig<T> = {
  defaultRowsPerPage: number;
  rowsPerPageOptions: number[];
  filterByKeyword?: (item: T, keyword: string) => boolean;
  exportHeaders: string[];
  exportFileName: string;
  getExportRows: (items: T[]) => string[][];
};

export const createTableConfig = <T>(
  options: Partial<TableConfig<T>> &
    Pick<TableConfig<T>, 'exportHeaders' | 'exportFileName' | 'getExportRows'>,
): TableConfig<T> => ({
  defaultRowsPerPage,
  rowsPerPageOptions: defaultRowsPerPageOptions,
  filterByKeyword: undefined,
  ...options,
});
