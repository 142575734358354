import { useQuery } from '@tanstack/react-query';
import { fetchSponsorDetails } from '../requests';
import { SponsorDetails } from '../../types';

const useFetchSponsorDetails = (folderId: string, sponsorExtractId: string) => {
  return useQuery({
    queryKey: ['sponsor_details'],
    queryFn: () => fetchSponsorDetails(folderId, sponsorExtractId),
    initialData: null,
    refetchInterval: (response) => {
      const data: SponsorDetails | null | undefined = response.state.data;

      if (data && data.source_retrieval_status !== 'in progress') {
        return false;
      }

      return 5000;
    },
  });
};

export default useFetchSponsorDetails;
