import { useEffect, useState } from 'react';
import { Tag } from '../../../services/api/workspace/types';
import { FolderTag } from '../../../services/api/workspace/types';

export interface MappedTag {
  selected: boolean;
  initialSelected: boolean;
  id: string;
  name: string;
  color: string;
  created_at: string;
  updated_at: string;
  project_id: string;
  folder_tags_relations: string[];
}

export const useMappedTags = (
  tags: Tag[],
  initialSelectedTags: FolderTag[],
  open: boolean,
): [MappedTag[], React.Dispatch<React.SetStateAction<MappedTag[]>>] => {
  const [mappedTags, setMappedTags] = useState<MappedTag[]>([]);

  useEffect(() => {
    if (open && tags.length) {
      const tagsChanged =
        tags.length !== mappedTags.length ||
        tags.some(
          (tag, index) =>
            tag.name !== mappedTags[index]?.name || tag.color !== mappedTags[index]?.color,
        );

      if (tagsChanged) {
        const newMappedTags: MappedTag[] = tags.map((tag) => {
          const selected = initialSelectedTags.some((selectedTag) => selectedTag.id === tag.id);
          return {
            ...tag,
            selected,
            initialSelected: selected,
          };
        });
        setMappedTags(newMappedTags);
      }
    }
  }, [open, tags, initialSelectedTags, mappedTags]);

  return [mappedTags, setMappedTags];
};
