import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Typography,
  TablePagination,
} from '@mui/material';
import { useDeleteItemConfirm, useToggle } from '../../../hooks';
import { CombinedExtract } from '../../../services/api/workspace/types';
import ToolbarExport from '../../shared/ToolbarExport';
import styles from './WorkspaceTable.module.css';
import TopicDetailsModal from '../TopicDetailsModal/TopicDetailsModal';
import PersonDetailsModal from '../PersonDetailsModal/PersonDetailsModal';
import WorkspaceItemMenu from '../WorkspaceItemMenu';
import PriorityFilterMenu from '../PriorityFilterMenu';
import TagFilterMenu from '../TagFilterMenu';

import ItemsNotFound from '../ItemsNotFound/ItemsNotFound';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoveToModal from '../MoveToModal/MoveToModal';
import FilterableTableCell from '../FilterableTableCell';
import {
  getItemType,
  workspaceTableConfig,
  useFilterMenus,
  useTablePagination,
} from './WorkspaceTable.utils';
import TableItemRow from '../TableItemRow/TableItemRow';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import useFetchTags from '../../../services/api/workspace/tags/hooks/useFetchTags';
import { Priorities } from '../../../enums';
import ItemCount from '../ItemCount/ItemCount';
import { useDeleteFolderPerson } from '../../../services/api/workspace/people/hooks';
import { useDeleteFolderTopic } from '../../../services/api/workspace/topics/hooks';
import { useFetchGroups } from '../../../services/api/workspace/groups/hooks';
import { useUserSettingsStore } from '../../../stores';
import SponsorDetailsModal from '../SponsorDetailsModal/SponsorDetailsModal';
import { useDeleteFolderSponsor } from '../../../services/api/workspace/sponsors/hooks';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { noop } from '../../../utils/common/common';

const columnWidth = {
  name: '25%',
  description: '40%',
  priority: '13%',
  tags: '18%',
  actions: '5%',
};

const { defaultRowsPerPage, exportFileName, exportHeaders, getExportRows, rowsPerPageOptions } =
  workspaceTableConfig;

const WorkspaceTable = () => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const deleteItemConfirm = useDeleteItemConfirm();

  const [isDefaultEditMode, setIsDefaultEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CombinedExtract | undefined>();
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const [topicDetailsModalOpen, toggleTopicDetailsModal] = useToggle(false);
  const [personDetailsModalOpen, togglePersonDetailsModal] = useToggle(false);
  const [sponsorDetailsModalOpen, toggleSponsorDetailsModal] = useToggle(false);
  const [moveToModalOpen, toggleMoveToModal] = useToggle(false);

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    paginationParams,
    requestSort,
    setPaginationParams,
    sortConfig,
  } = useTablePagination();

  const {
    priorityFilterAnchor,
    tagFilterAnchor,
    handleOpenPriorityFilterMenu,
    handleClosePriorityFilterMenu,
    handleOpenTagFilterMenu,
    handleCloseTagFilterMenu,
  } = useFilterMenus();

  const { mutate: deleteFolderPerson } = useDeleteFolderPerson();
  const { mutate: deleteFolderTopic } = useDeleteFolderTopic();
  const { mutate: deleteFolderSponsor } = useDeleteFolderSponsor();

  const {
    data: { data: groups, totalCount },
    refetch: refetchGroups,
    isFetchedAfterMount,
  } = useFetchGroups({
    folder_id: selectedFolderId,
    ...paginationParams,
  });

  const { data: tags } = useFetchTags({});

  useEffect(() => {
    refetchGroups();
  }, [paginationParams, refetchGroups, selectedFolderId]);

  useEffect(() => {
    if (groups.length > 0) {
      const initialExpandedState = groups.reduce(
        (acc, group) => {
          acc[group.group_id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setExpandedGroups(initialExpandedState);
    }
  }, [groups]);

  const selectPriorities = (priorities: Priorities[]) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      priority: priorities,
    }));
  };

  const selectTags = (tags: string[]) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      tags,
    }));
  };

  const toggleGroup = (groupId: string) => {
    setExpandedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, item: CombinedExtract) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setSelectedItem(item);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleMenuEdit = () => {
    if (selectedItem) {
      onRowClick(selectedItem, true);
    }
  };

  const onRowClick = (item: CombinedExtract, isDefaultEditMode: boolean) => {
    setIsDefaultEditMode(isDefaultEditMode);
    setSelectedItem(item);

    if (item.topic_extract_id) {
      toggleTopicDetailsModal();
    } else if (item.people_extract_id) {
      togglePersonDetailsModal();
    } else {
      toggleSponsorDetailsModal();
    }
  };

  const handleMoveTo = () => {
    toggleMoveToModal();
    setMenuAnchor(null);
  };

  const handleDeleteItem = () => {
    if (selectedItem) {
      const entities = [
        { id: selectedItem.topic_extract_id, mutation: deleteFolderTopic, type: 'topic' },
        { id: selectedItem.people_extract_id, mutation: deleteFolderPerson, type: 'person' },
        { id: selectedItem.sponsor_extract_id, mutation: deleteFolderSponsor, type: 'sponsor' },
      ];

      const {
        mutation: deleteMutation,
        type: itemType,
        id,
      } = entities.find((entity) => entity.id) || {};

      const title = `Are you sure you want to delete this ${itemType}?`;
      deleteItemConfirm(title, selectedItem.name)
        .then(() => deleteMutation?.(id || ''))
        .catch(noop);
    }
  };

  const exportRows = getExportRows(groups);

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return isFetchedAfterMount ? (
    <Paper className={styles.container}>
      <Box className={styles.content}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <SortableTableCell
                  columnKey="group_name"
                  hoveredColumn={hoveredColumn}
                  sortConfig={sortConfig}
                  setHoveredColumn={setHoveredColumn}
                  requestSort={requestSort}
                  style={{ width: columnWidth.name }}
                >
                  Topics/People
                </SortableTableCell>
                <SortableTableCell
                  columnKey="description"
                  hoveredColumn={hoveredColumn}
                  sortConfig={sortConfig}
                  setHoveredColumn={setHoveredColumn}
                  requestSort={requestSort}
                  style={{ width: columnWidth.description }}
                >
                  Description
                </SortableTableCell>

                <FilterableTableCell
                  label="Priority"
                  width={columnWidth.priority}
                  onClick={handleOpenPriorityFilterMenu}
                  filtersCount={paginationParams.priority?.length || 0}
                />
                <FilterableTableCell
                  label="Tags"
                  width={columnWidth.tags}
                  onClick={handleOpenTagFilterMenu}
                  filtersCount={paginationParams.tags?.length || 0}
                />
                <TableCell style={{ width: columnWidth.actions }}>
                  <ToolbarExport onExportCSV={handleExportCSV} onExportExcel={handleExportExcel} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.length ? (
                groups.map((group) => (
                  <React.Fragment key={group.group_id}>
                    <TableRow className={styles.groupRow}>
                      <TableCell colSpan={6}>
                        <Box display="flex" alignItems="center">
                          <IconButton onClick={() => toggleGroup(group.group_id)}>
                            {expandedGroups[group.group_id] ? (
                              <KeyboardArrowUpIcon color="primary" />
                            ) : (
                              <KeyboardArrowDownIcon color="primary" />
                            )}
                          </IconButton>
                          <Typography mr={1} className={styles.groupName}>
                            {group.group_name}
                          </Typography>
                          <ItemCount label={group.combined_extracts.length} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse in={expandedGroups[group.group_id]} timeout="auto" unmountOnExit>
                          <Table>
                            <TableBody>
                              {group.combined_extracts.map((item: CombinedExtract) => {
                                const extractType = getItemType({
                                  people_extract_id: item.people_extract_id,
                                  sponsor_extract_id: item.sponsor_extract_id,
                                  topic_extract_id: item.topic_extract_id,
                                });

                                return (
                                  <TableItemRow
                                    key={
                                      item.people_extract_id ||
                                      item.topic_extract_id ||
                                      item.sponsor_extract_id
                                    }
                                    item={item}
                                    extractType={extractType}
                                    onRowClick={onRowClick}
                                    handleOpenMenu={handleOpenMenu}
                                    columnWidth={columnWidth}
                                    tags={item.tags}
                                  />
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <ItemsNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={+totalCount}
          rowsPerPage={paginationParams?.size || defaultRowsPerPage}
          page={paginationParams.page ? paginationParams.page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Groups per page"
        />
      </Box>
      {topicDetailsModalOpen && (
        <TopicDetailsModal
          open={topicDetailsModalOpen}
          onClose={toggleTopicDetailsModal}
          toggleMoveToModal={toggleMoveToModal}
          topicExtractId={selectedItem?.topic_extract_id || ''}
        />
      )}

      {personDetailsModalOpen && (
        <PersonDetailsModal
          isDefaultEditMode={isDefaultEditMode}
          open={personDetailsModalOpen}
          onClose={togglePersonDetailsModal}
          toggleMoveToModal={toggleMoveToModal}
          personExtractId={selectedItem?.people_extract_id || ''}
        />
      )}

      {sponsorDetailsModalOpen && (
        <SponsorDetailsModal
          open={sponsorDetailsModalOpen}
          onClose={toggleSponsorDetailsModal}
          toggleMoveToModal={toggleMoveToModal}
          sponsorExtractId={selectedItem?.sponsor_extract_id || ''}
        />
      )}

      <WorkspaceItemMenu
        onDelete={handleDeleteItem}
        onEdit={selectedItem?.people_extract_id ? handleMenuEdit : undefined}
        onMoveTo={handleMoveTo}
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
      />
      <PriorityFilterMenu
        anchorEl={priorityFilterAnchor}
        onClose={handleClosePriorityFilterMenu}
        onSelect={selectPriorities}
        selectedPriorities={paginationParams.priority || []}
      />
      <TagFilterMenu
        anchorEl={tagFilterAnchor}
        onClose={handleCloseTagFilterMenu}
        onSelect={selectTags}
        availableTags={tags}
        selectedTagNames={paginationParams.tags || []}
      />
      {moveToModalOpen && (
        <MoveToModal
          selectedItem={selectedItem}
          onClose={toggleMoveToModal}
          open={moveToModalOpen}
        />
      )}
    </Paper>
  ) : null;
};

export default WorkspaceTable;
