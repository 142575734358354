import { useState } from 'react';
import { CombinedExtract, FetchGroupsPayload, Group } from '../../../services/api/workspace/types';
import { ExtractType, SortDirection } from '../../../enums';
import { SortConfig } from '../../../hooks/useTableUtils';
import { createTableConfig } from '../../../utils/table/table';

export const getItemTypeLabelForExport = (item: CombinedExtractWithGroupName) => {
  if (item.people_extract_id) return 'Person';
  if (item.sponsor_extract_id) return 'Sponsor';
  return 'Topic';
};

export const getExportRows = (groups: Group[]) => {
  const combinedExtracts = groups.reduce<CombinedExtractWithGroupName[]>(
    (acc, curr) => [
      ...acc,
      ...curr.combined_extracts.map((extract) => ({ ...extract, groupName: curr.group_name })),
    ],
    [],
  );

  return combinedExtracts.map((item) => {
    const type = getItemTypeLabelForExport(item);
    const tags = item.tags.map((tag) => tag.name).join(', ');
    return [item.name, item.description, type, item.groupName, item.priority, tags];
  });
};

export const workspaceTableConfig = createTableConfig({
  exportHeaders: ['Name', 'Description', 'Type', 'Group', 'Priority', 'Tags'],
  exportFileName: 'workspace_data',
  getExportRows,
});

interface CombinedExtractWithGroupName extends CombinedExtract {
  groupName: string;
}

export const getItemType = (item: {
  people_extract_id?: string;
  topic_extract_id?: string;
  sponsor_extract_id?: string;
}): ExtractType => {
  if (item.people_extract_id) {
    return ExtractType.PEOPLE;
  }
  if (item.topic_extract_id) {
    return ExtractType.TOPIC;
  }

  return ExtractType.SPONSOR;
};

export const useFilterMenus = () => {
  const [priorityFilterAnchor, setPriorityFilterAnchor] = useState<null | HTMLElement>(null);
  const [tagFilterAnchor, setTagFilterAnchor] = useState<null | HTMLElement>(null);

  const handleOpenPriorityFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPriorityFilterAnchor(event.currentTarget);
  };

  const handleClosePriorityFilterMenu = () => {
    setPriorityFilterAnchor(null);
  };

  const handleOpenTagFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setTagFilterAnchor(event.currentTarget);
  };

  const handleCloseTagFilterMenu = () => {
    setTagFilterAnchor(null);
  };

  return {
    priorityFilterAnchor,
    tagFilterAnchor,
    handleOpenPriorityFilterMenu,
    handleClosePriorityFilterMenu,
    handleOpenTagFilterMenu,
    handleCloseTagFilterMenu,
  };
};

export const useTablePagination = () => {
  const [paginationParams, setPaginationParams] = useState<Omit<FetchGroupsPayload, 'folder_id'>>(
    {},
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationParams((prevParams) => ({ ...prevParams, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      page: 1,
      size: +event.target.value,
    }));
  };

  const requestSort = (key: string) => {
    setPaginationParams((prevParams) => {
      if (prevParams.sort_by === key) {
        return prevParams.sort_order === SortDirection.ASC
          ? { ...prevParams, sort_by: key, sort_order: SortDirection.DESC }
          : { ...prevParams, sort_by: null, sort_order: null };
      }
      return { ...prevParams, sort_by: key, sort_order: SortDirection.ASC };
    });
  };

  const sortConfig: SortConfig = {
    key: paginationParams.sort_by || '',
    direction: paginationParams.sort_order || null,
  };

  return {
    paginationParams,
    setPaginationParams,
    handleChangePage,
    handleChangeRowsPerPage,
    requestSort,
    sortConfig,
  };
};
