import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSponsorNote } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useCreateSponsorNote = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ sponsor_extract_id, text }: { sponsor_extract_id: string; text: string }) =>
      createSponsorNote(sponsor_extract_id, text),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
      showSnackbar(Messages.SUCCESS.NOTE_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateSponsorNote;
