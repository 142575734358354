import React from 'react';
import { Box, Link, Skeleton, TextField, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import NoDataLabel from '../NoDataLabel';

interface PersonContactInfoProps {
  linkedInUrl: string | null;
  phone: string;
  email: string;
  isEditMode: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadingData?: boolean;
}

const getIconColor = (value: string | null) => (value ? 'primary' : 'disabled');

const PersonContactInfo: React.FC<PersonContactInfoProps> = ({
  linkedInUrl,
  phone,
  email,
  isEditMode,
  handleInputChange,
  loadingData = false,
}) => {
  return (
    <>
      <Box display="flex" alignItems="center" mb={2} color="primary">
        <LinkedInIcon style={{ marginRight: 8 }} color={getIconColor(linkedInUrl)} />
        {!linkedInUrl && loadingData && <Skeleton variant="text" width={200} />}
        {!linkedInUrl && !loadingData && <NoDataLabel />}
        {linkedInUrl && (
          <Link href={linkedInUrl} target="_blank" display="flex" alignItems="center">
            <Typography>{linkedInUrl}</Typography>
          </Link>
        )}
      </Box>

      <Box style={{ marginBottom: isEditMode ? 11 : 16 }} display="flex" alignItems="center">
        <LocalPhoneIcon style={{ marginRight: 8 }} color={getIconColor(phone)} />
        {!phone && loadingData && <Skeleton variant="text" width={80} />}
        {!phone && !loadingData && <NoDataLabel />}
        {phone && isEditMode && (
          <TextField
            name="phone"
            style={{ width: '100%' }}
            size="small"
            variant="standard"
            value={phone}
            onChange={handleInputChange}
          />
        )}
        {phone && !isEditMode && (
          <Link color="primary" href={`tel:${phone}`}>
            {phone}
          </Link>
        )}
      </Box>

      <Box style={{ marginBottom: isEditMode ? 11 : 16 }} display="flex" alignItems="center">
        <EmailIcon style={{ marginRight: 8 }} color={getIconColor(email)} />
        {!email && loadingData && <Skeleton variant="text" width={140} />}
        {!email && !loadingData && <NoDataLabel />}

        {email && isEditMode && (
          <TextField
            name="email"
            fullWidth
            size="small"
            variant="standard"
            value={email}
            onChange={handleInputChange}
          />
        )}
        {email && !isEditMode && (
          <Link color="primary" href={`mailto:${email}`}>
            {email}
          </Link>
        )}
      </Box>
    </>
  );
};

export default PersonContactInfo;
