import React, { useMemo, useState } from 'react';
import {
  Typography,
  Toolbar,
  Icon,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  TablePagination,
} from '@mui/material';
import styles from './SponsorsTable.module.css';
import { flattenSponsorsExtracts, sponsorsTableConfig } from './SponsorsTable.utils';
import { useTableUtils, useToggle } from '../../../hooks';

import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import StoreIcon from '@mui/icons-material/Store';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { SponsorResult } from '../../../services/api/search/types';
import { useManageSponsorFolders } from '../../../services/api/workspace/sponsors/hooks';
import { useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import { Folder } from '../../../services/api/workspace/types';
import PickFolderModal from '../PickFolderModal/PickFolderModal';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { getDomainName } from '../../../utils/string/string';

interface SponsorsTableProps {
  data: SponsorResult[];
}

const {
  defaultRowsPerPage,
  exportFileName,
  exportHeaders,
  getExportRows,
  filterByKeyword,
  rowsPerPageOptions,
} = sponsorsTableConfig;

const SponsorsTable: React.FC<SponsorsTableProps> = ({ data }) => {
  const flattenedSponsors = flattenSponsorsExtracts(data);
  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);
  const [selectedSponsorId, setSelectedSponsorId] = useState<string | null>(null);
  const [initialSelectedFoldersIds, setInitialSelectedFoldersIds] = useState<string[]>([]);

  const {
    data: paginatedSponsors,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
  } = useTableUtils(flattenedSponsors, defaultRowsPerPage, filterByKeyword);

  const { mutate: manageSponsorFolders } = useManageSponsorFolders();
  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const sponsorsIdsInFolders = useMemo(
    () =>
      folders.reduce((acc: string[], item: Folder) => {
        return [...acc, ...item.sponsor_extracts_ids];
      }, []),
    [folders],
  );

  const handleSave = (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => {
    if (selectedSponsorId) {
      manageSponsorFolders({
        foldersIdsToAdd,
        foldersIdsToRemove,
        sponsorExtractId: selectedSponsorId,
      });
    }
  };

  const handleToggleFolderButton = (sponsorId: string) => {
    const sponsorsFoldersIds = folders.reduce((acc: string[], item: Folder) => {
      if (item.sponsor_extracts_ids.includes(sponsorId)) return [...acc, item.id];
      return acc;
    }, []);

    setSelectedSponsorId(sponsorId);
    setInitialSelectedFoldersIds(sponsorsFoldersIds);
    togglePickFolderModalOpen();
  };

  const exportRows = getExportRows(flattenedSponsors);

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <Toolbar>
        <Box className={styles.titleContainer}>
          <Icon className={styles.titleIcon} color="inherit" aria-label="menu">
            <StoreIcon />
          </Icon>
          <Typography className={styles.title} variant="h6" component="div">
            Trending Sponsors
          </Typography>
        </Box>
      </Toolbar>
      <Box className={styles.content}>
        <TableToolbar
          onFilter={onFilter}
          onExportCSV={handleExportCSV}
          onExportExcel={handleExportExcel}
        />
        {foldersFetched && (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <SortableTableCell
                      columnKey="company_name"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Company name
                    </SortableTableCell>
                    <TableCell>Actions</TableCell>
                    <SortableTableCell
                      columnKey="description"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Description
                    </SortableTableCell>
                    <TableCell>Competitor events</TableCell>

                    <SortableTableCell
                      align="center"
                      columnKey="relevancy_score"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                      tableCellContentStyle={{ marginLeft: 24 }}
                    >
                      Relevancy score
                    </SortableTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedSponsors.map((sponsor) => (
                    <React.Fragment key={sponsor.id}>
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight={600} variant="body2">
                            {sponsor.company_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box className={styles.actions}>
                            <AddToWorkspaceButton
                              onClick={() => handleToggleFolderButton(sponsor.id)}
                              defaultValue={sponsorsIdsInFolders.includes(sponsor.id)}
                            />
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Typography mb={0.5} variant="body2">
                            {sponsor.description}
                          </Typography>
                          <Link
                            href={sponsor.company_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.link}
                          >
                            {getDomainName(sponsor.company_link)}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {sponsor.competitor_events.map((event, index) => {
                            const notLastElement = index < sponsor.competitor_events.length - 1;
                            const eventDisplayName = `${event}${notLastElement ? ';' : ''}`;

                            return (
                              <React.Fragment key={index}>
                                {eventDisplayName}
                                {notLastElement && <br />}
                              </React.Fragment>
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontWeight={600} variant="body2">
                            No data
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              defaultValue={defaultRowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {pickFolderModalOpen && (
              <PickFolderModal
                open={pickFolderModalOpen}
                onClose={togglePickFolderModalOpen}
                onSave={handleSave}
                initialSelectedFoldersIds={initialSelectedFoldersIds}
                folders={folders}
              />
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default SponsorsTable;
