import { TopicResult } from '../../../services/api/search/types';
import { createTableConfig } from '../../../utils/table/table';

export interface TopicExtract {
  name: string;
  description: string;
  url: string;
  id: string;
  matchScore: number;
}

export const topicsTableConfig = createTableConfig<TopicExtract>({
  exportHeaders: ['Topic', 'Description'],
  exportFileName: 'trending_topics',
  getExportRows: (topics: TopicExtract[]) => topics.map((topic) => [topic.name, topic.description]),
  filterByKeyword: (topic: TopicExtract, keyword: string) =>
    topic.name.toLowerCase().includes(keyword.toLowerCase()) ||
    topic.description.toLowerCase().includes(keyword.toLowerCase()),
});

export const flattenTopicsExtracts = (topics: TopicResult[]) => {
  return topics.reduce((acc: TopicExtract[], current) => {
    if (current.extracts) {
      const extracts = current.extracts.map((extract) => ({
        name: extract.topic,
        description: extract.reasoning,
        url: current.url,
        id: extract.id,
        matchScore: current.match_score,
      }));

      return acc.concat(extracts);
    }
    return acc;
  }, []);
};
