import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { PaginationParams } from '../../types';
import { Tag } from '../types';

export const createTag = (payload: { name: string; color: string }) => {
  return apiClient.post(`${BASE_API_URL}/tags`, payload).then((response) => response.data);
};

export const updateTag = (tagId: string, payload: { name: string; color: string }) => {
  return apiClient.put(`${BASE_API_URL}/tags/${tagId}`, payload).then((response) => response.data);
};

export const deleteTag = (tag_id: string) => {
  return apiClient.delete(`${BASE_API_URL}/tags/${tag_id}`).then((response) => response.data);
};

export const fetchTags = (payload: PaginationParams): Promise<Tag[]> => {
  return apiClient
    .get(`${BASE_API_URL}/tags`, { params: payload })
    .then((response) => response.data);
};

export const manageTopicTags = ({
  topicExtractId,
  tagsIdsToAdd,
  tagsIdsToRemove,
}: {
  topicExtractId: string;
  tagsIdsToAdd: string[];
  tagsIdsToRemove: string[];
}) => {
  return apiClient
    .put(`${BASE_API_URL}/tags-extracts/topic_extracts/${topicExtractId}`, {
      to_add: tagsIdsToAdd,
      to_remove: tagsIdsToRemove,
    })
    .then((response) => response.data);
};

export const managePersonTags = ({
  personExtractId,
  tagsIdsToAdd,
  tagsIdsToRemove,
}: {
  personExtractId: string;
  tagsIdsToAdd: string[];
  tagsIdsToRemove: string[];
}) => {
  return apiClient
    .put(`${BASE_API_URL}/tags-extracts/people_extracts/${personExtractId}`, {
      to_add: tagsIdsToAdd,
      to_remove: tagsIdsToRemove,
    })
    .then((response) => response.data);
};

export const manageSponsorTags = ({
  sponsorExtractId,
  tagsIdsToAdd,
  tagsIdsToRemove,
}: {
  sponsorExtractId: string;
  tagsIdsToAdd: string[];
  tagsIdsToRemove: string[];
}) => {
  return apiClient
    .put(`${BASE_API_URL}/tags-extracts/sponsors_extracts/${sponsorExtractId}`, {
      to_add: tagsIdsToAdd,
      to_remove: tagsIdsToRemove,
    })
    .then((response) => response.data);
};

export const deleteTagFromExtract = (tagExtractId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/tags-extracts/${tagExtractId}`)
    .then((response) => response.data);
};
