import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Link,
  SelectChangeEvent,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styles from './TopicDetailsModal.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import NotesSection from '../NotesSection/NotesSection';
import DetailsModalActions from '../DetailsModalActions/DetailsModalActions';
import ItemInfoLabel from '../ItemInfoLabel';
import WorkspaceItemMenu from '../WorkspaceItemMenu';

import { useDeleteItemConfirm } from '../../../hooks';
import { Priorities } from '../../../enums';
import {
  useCreateTopicNote,
  useDeleteNoteFromTopicExtract,
  useFetchTopicNotes,
} from '../../../services/api/workspace/notes/hooks';
import {
  useFetchTopicNotesAttachments,
  useUploadNoteAttachmentToTopic,
} from '../../../services/api/workspace/notesAttachments/hooks';
import {
  useDeleteFolderTopic,
  useFetchTopicDetails,
  useUpdateTopicDetails,
} from '../../../services/api/workspace/topics/hooks';
import { useManageTopicTags } from '../../../services/api/workspace/tags/hooks';
import { useUserSettingsStore } from '../../../stores';
import DetailsSubtitle from '../DetailsSubtitle';
import { getDomainName } from '../../../utils/string/string';
import { noop } from '../../../utils/common/common';

interface TopicDetailsModalProps {
  open: boolean;
  onClose: () => void;
  toggleMoveToModal: () => void;
  topicExtractId: string;
}

const TopicDetailsModal: React.FC<TopicDetailsModalProps> = ({
  open,
  onClose,
  topicExtractId,
  toggleMoveToModal,
}) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const deleteTopicConfirm = useDeleteItemConfirm();

  const [noteText, setNoteText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: notes } = useFetchTopicNotes({
    topic_extract_id: topicExtractId,
  });
  const { data: notesAttachments } = useFetchTopicNotesAttachments(topicExtractId, {});

  const { data: topicDetails, refetch } = useFetchTopicDetails(selectedFolderId, topicExtractId);
  const { mutate: uploadNoteAttachmentToTopic } = useUploadNoteAttachmentToTopic();

  const { mutate: deleteTopic } = useDeleteFolderTopic();
  const { mutate: createTopicNote } = useCreateTopicNote();
  const { mutate: updateTopicDetails } = useUpdateTopicDetails();
  const { mutate: manageTopicTags } = useManageTopicTags();
  const { mutate: deleteNoteFromTopicExtract } = useDeleteNoteFromTopicExtract();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteTopic = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    deleteTopicConfirm('Are you sure you want to delete this topic?', topicDetails?.name || '')
      .then(() => deleteTopic(topicExtractId, { onSuccess: () => onClose() }))
      .catch(noop);
  };

  const handleMoveTo = () => {
    onClose();
    toggleMoveToModal();
  };

  const handleCreateTextNote = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (noteText.trim().length) {
      createTopicNote({
        topic_extract_id: topicExtractId,
        text: noteText,
      });
      setNoteText('');
    }
  };

  const handleChangePriority = (event: SelectChangeEvent<string>) => {
    updateTopicDetails({
      topicId: topicExtractId,
      payload: { priority: event.target.value as Priorities },
    });
  };

  const handleManageTags = (tagsIdsToAdd: string[], tagsIdsToRemove: string[]) => {
    manageTopicTags({
      topicExtractId: topicExtractId,
      tagsIdsToAdd,
      tagsIdsToRemove,
    });
  };

  const tags =
    topicDetails?.folder_tags.map((folderTag) => ({
      ...folderTag.folder_tag,
      tagExtractId: folderTag.id,
    })) || [];

  const refetchTopicDetails = () => {
    refetch();
  };

  const handleUploadNoteAttachment = (file: File) => {
    uploadNoteAttachmentToTopic({ topicExtractId, file });
  };

  const deleteNote = (noteId: string, topicExtractId: string) => {
    deleteNoteFromTopicExtract({ noteId, topicExtractId });
  };

  return (
    <Dialog
      PaperProps={{
        className: styles.dialogPaper,
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
    >
      {topicDetails && (
        <DialogContent>
          <Box mb={4}>
            <Box className={styles.titleBox}>
              <Typography variant="h5">{topicDetails.name}</Typography>

              <Box display="flex">
                <IconButton onClick={handleOpenMenu}>
                  <MoreVertIcon color="primary" />
                </IconButton>
                <IconButton onClick={onClose}>
                  <CloseIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
            <Link
              display="flex"
              alignItems="center"
              color="primary"
              href={topicDetails?.source_url}
              target="_blank"
            >
              <OpenInNewIcon color="primary" />
              <Typography ml={1}>{getDomainName(topicDetails.source_url)}</Typography>
            </Link>
          </Box>
          <DetailsModalActions
            priorityValue={topicDetails.priority}
            onPriorityChange={handleChangePriority}
            tags={tags}
            manageTags={handleManageTags}
            refetchItemDetails={refetchTopicDetails}
          />

          <ItemInfoLabel
            style={{ marginBottom: 16 }}
            title="Relevancy score"
            value={topicDetails.relevancy_score}
          />

          <DetailsSubtitle style={{ marginBottom: 8 }}>Description</DetailsSubtitle>
          <Typography mb={2}>{topicDetails.topic_extract.reasoning}</Typography>

          <NotesSection
            noteText={noteText}
            onChange={setNoteText}
            notes={notes}
            onSubmit={handleCreateTextNote}
            notesAttachments={notesAttachments}
            deleteNote={deleteNote}
            uploadNoteAttachment={handleUploadNoteAttachment}
            extractId={topicExtractId}
          />
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      <WorkspaceItemMenu
        onDelete={handleDeleteTopic}
        onMoveTo={handleMoveTo}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      />
    </Dialog>
  );
};

export default TopicDetailsModal;
