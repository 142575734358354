import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
  Container,
  Link,
  InputAdornment,
  Box,
} from '@mui/material';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import { useAuth } from '../../services/auth';
import styles from './Login.module.css';
import { useToggle } from '../../hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const [passwordVisible, togglePasswordVisibility] = useToggle(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    document.body.classList.add(styles.loginBackground);

    return () => {
      document.body.classList.remove(styles.loginBackground);
    };
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const success = await auth.signIn(email, password);
      if (success) {
        navigate('/dashboard');
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <Container component="main" className={styles.mainContainer}>
      <Grid container className={styles.gridContainer} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          container
          alignItems="center"
          justifyContent="center"
        >
          <BlurOnRoundedIcon className={styles.icon} />
        </Grid>
        <Grid item xs={12} sm={8} md={6} container alignItems="center" justifyContent="center">
          <Paper elevation={6} className={styles.paper}>
            <Typography variant="h5" component="h3" gutterBottom>
              EventProducer Pro
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                error={showError}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={passwordVisible ? 'text' : 'password'}
                autoComplete="current-password"
                value={password}
                error={showError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={togglePasswordVisibility}>
                        {passwordVisible ? (
                          <VisibilityOffIcon color="disabled" />
                        ) : (
                          <VisibilityIcon color="disabled" />
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showError && (
                <Box display="flex" alignItems="center">
                  <InfoIcon sx={{ color: '#FF5252' }} />
                  <Typography variant="body2" ml={1} color="#FF5252">
                    Incorrect email or password. Please try again.
                  </Typography>
                </Box>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                Login
              </Button>
              <Grid container justifyContent="center" alignItems="center">
                <Link className={styles.forgotPassword} href="/password-reset">
                  Forgot your password?
                </Link>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
