import React from 'react';
import { TableRow, TableCell, Box, IconButton, Typography } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import PriorityIndicator from '../PriorityIndicator';
import TagIndicator from '../TagIndicator/TagIndicator';
import { DotColors } from '../../../constants/tagColorPalette';
import styles from './TableItemRow.module.css';
import { CombinedExtract, Tag } from '../../../services/api/workspace/types';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import StoreIcon from '@mui/icons-material/Store';
import { ExtractType } from '../../../enums';

interface TableItemRowProps {
  item: CombinedExtract;
  onRowClick: (item: any, isDefaultEditMode: boolean) => void;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>, item: any) => void;
  columnWidth: {
    name: string;
    description: string;
    priority: string;
    tags: string;
    actions: string;
  };
  tags: Tag[];
  extractType: ExtractType;
}

const iconByExtract = {
  [ExtractType.PEOPLE]: PersonIcon,
  [ExtractType.TOPIC]: ChatIcon,
  [ExtractType.SPONSOR]: StoreIcon,
};

const TableItemRow: React.FC<TableItemRowProps> = ({
  item,
  onRowClick,
  handleOpenMenu,
  columnWidth,
  tags,
  extractType,
}) => {
  const ItemTypeIcon = iconByExtract[extractType];

  const displayedTags = tags.slice(0, 3);

  return (
    <TableRow onClick={() => onRowClick(item, false)} className={styles.dataRow}>
      <TableCell />
      <TableCell style={{ width: columnWidth.name }}>
        <Box display="flex" alignItems="center">
          <ItemTypeIcon className={styles.itemTypeIcon} />
          <Typography className={styles.nameLabel}>{item.name}</Typography>
        </Box>
      </TableCell>
      <TableCell style={{ width: columnWidth.description }}>
        {item.description || 'No description available'}
      </TableCell>
      <TableCell style={{ width: columnWidth.priority }}>
        <PriorityIndicator type={item.priority} />
      </TableCell>
      <TableCell style={{ width: columnWidth.tags }}>
        <Box>
          {displayedTags.map((tag, index) => (
            <TagIndicator
              key={tag.id}
              backgroundColor={tag.color}
              dotColor={DotColors[tag.color]}
              label={tag.name}
              style={{
                marginBottom: index < displayedTags.length - 1 ? 8 : 0,
              }}
            />
          ))}
          {tags.length > 3 && (
            <Box className={styles.itemCount}>
              <Typography fontSize={12}>+{tags.length - displayedTags.length}</Typography>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell style={{ width: columnWidth.actions }}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleOpenMenu(event, item);
          }}
          size="small"
        >
          <MoreVert color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableItemRow;
