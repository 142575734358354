import { useQuery } from '@tanstack/react-query';
import { PaginationParams } from '../../../types';
import { fetchSponsorNotesAttachments } from '../requests';

const useFetchSponsorNotesAttachments = (sponsorExtractId: string, payload: PaginationParams) => {
  return useQuery({
    queryKey: ['notes_attachments', sponsorExtractId],
    queryFn: () => fetchSponsorNotesAttachments(sponsorExtractId, payload),
    initialData: [],
  });
};

export default useFetchSponsorNotesAttachments;
