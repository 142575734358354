import React, { memo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
  Box,
  CircularProgress,
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import styles from './SponsorContactsTable.module.css';
import { DetailsRetrievalStatus, Employee } from '../../../services/api/workspace/types';
import DetailsTableItemsNotFound from '../DetailsTableItemsNotFound';
import { generateLinkedinByUsername } from '../../../utils/string/string';

interface SponsorContactsTableProps {
  contacts: Employee[];
  retrievalStatus: DetailsRetrievalStatus;
}

const SponsorContactsTable: React.FC<SponsorContactsTableProps> = ({
  contacts,
  retrievalStatus,
}) => {
  const showTableLoader = retrievalStatus === 'in progress' && !contacts.length;
  const showNoRecords =
    (retrievalStatus === 'done' || retrievalStatus === 'partially_done') && !contacts.length;

  return (
    <Paper className={styles.container}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contact person</TableCell>
              <TableCell>Role/position</TableCell>
              <TableCell>Social Media</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showTableLoader && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {showNoRecords && <DetailsTableItemsNotFound title="No Contacts Found" />}
            {!showTableLoader &&
              !showNoRecords &&
              contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell>{contact.full_name}</TableCell>
                  <TableCell>{contact.job_title}</TableCell>
                  <TableCell>
                    <Link
                      href={generateLinkedinByUsername(contact.source_username)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon
                        fontSize="large"
                        className={styles.linkedInIcon}
                        color="primary"
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default memo(SponsorContactsTable);
