import React, { useMemo, useState } from 'react';
import {
  Typography,
  Toolbar,
  Icon,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Button,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SearchIcon from '@mui/icons-material/Search';
import styles from './TopicsTable.module.css';
import { TopicResult } from '../../../services/api/search/types';
import { topicsTableConfig, flattenTopicsExtracts } from './TopicsTable.utils';
import { useTableUtils, useToggle } from '../../../hooks';
import PickFolderModal from '../PickFolderModal/PickFolderModal';
import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { useRunSearch } from '../../../services/api/search/hooks';
import { Link } from 'react-router-dom';
import { useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import { useManageTopicFolders } from '../../../services/api/workspace/topics/hooks';
import { Folder } from '../../../services/api/workspace/types';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { getDomainName, pickMatchScoreLabelColor } from '../../../utils/string/string';

interface TopicsTableProps {
  data: TopicResult[];
}

const {
  defaultRowsPerPage,
  exportHeaders,
  exportFileName,
  getExportRows,
  filterByKeyword,
  rowsPerPageOptions,
} = topicsTableConfig;

const TopicsTable: React.FC<TopicsTableProps> = ({ data }) => {
  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);
  const [initialSelectedFoldersIds, setInitialSelectedFoldersIds] = useState<string[]>([]);

  const { mutate: runSearch } = useRunSearch();
  const { mutate: manageTopicFolders } = useManageTopicFolders();

  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const flattenedTopics = flattenTopicsExtracts(data);

  const {
    data: paginatedTopics,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
  } = useTableUtils(flattenedTopics, defaultRowsPerPage, filterByKeyword);

  const topicsIdsInFolders = useMemo(
    () =>
      folders.reduce((acc: string[], item: Folder) => {
        return [...acc, ...item.topic_extracts_ids];
      }, []),
    [folders],
  );

  const exportRows = getExportRows(flattenedTopics);

  const handleSave = (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => {
    if (selectedTopicId) {
      manageTopicFolders({ foldersIdsToAdd, foldersIdsToRemove, topicExtractId: selectedTopicId });
    }
  };

  const handleToggleFolderButton = (topicId: string) => {
    const topicsFoldersIds = folders.reduce((acc: string[], item: Folder) => {
      if (item.topic_extracts_ids.includes(topicId)) return [...acc, item.id];
      return acc;
    }, []);

    setSelectedTopicId(topicId);
    setInitialSelectedFoldersIds(topicsFoldersIds);
    togglePickFolderModalOpen();
  };

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <Toolbar>
        <Icon color="inherit" aria-label="menu" className={styles.titleIcon}>
          <ChatIcon />
        </Icon>

        <Typography className={styles.title} variant="h6" component="div">
          Trending Topics
        </Typography>
      </Toolbar>
      <Box className={styles.content}>
        <TableToolbar
          onFilter={onFilter}
          onExportCSV={handleExportCSV}
          onExportExcel={handleExportExcel}
        />

        {foldersFetched && (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <SortableTableCell
                      columnKey="name"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Topic
                    </SortableTableCell>
                    <TableCell>Actions</TableCell>
                    <SortableTableCell
                      columnKey="description"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Description
                    </SortableTableCell>
                    <SortableTableCell
                      columnKey="matchScore"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                      tableCellContentStyle={{ marginLeft: 24 }}
                      align="center"
                    >
                      Relevancy score
                    </SortableTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedTopics.map((topic) => (
                    <TableRow key={topic.id}>
                      <TableCell>
                        <Typography fontWeight={600} variant="body2">
                          {topic.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box className={styles.actions}>
                          <AddToWorkspaceButton
                            onClick={() => handleToggleFolderButton(topic.id)}
                            defaultValue={topicsIdsInFolders.includes(topic.id)}
                            buttonClassName={styles.addToWorkspaceButton}
                          />
                          <Tooltip placement="top" title="Run search for this subtopic">
                            <Button
                              variant="outlined"
                              color="info"
                              size="small"
                              onClick={() => runSearch(topic.name)}
                            >
                              <SearchIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography mb={0.5} variant="body2">
                          {topic.description}
                        </Typography>
                        <Link
                          to={topic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.link}
                        >
                          {getDomainName(topic.url)}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          color={pickMatchScoreLabelColor(topic.matchScore)}
                          fontWeight={600}
                          variant="body2"
                        >
                          {Math.trunc(topic.matchScore)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={flattenedTopics.length}
              rowsPerPage={rowsPerPage}
              page={page}
              defaultValue={defaultRowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {pickFolderModalOpen && (
              <PickFolderModal
                open={pickFolderModalOpen}
                onClose={togglePickFolderModalOpen}
                onSave={handleSave}
                initialSelectedFoldersIds={initialSelectedFoldersIds}
                folders={folders}
              />
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default TopicsTable;
