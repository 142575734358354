import React from 'react';
import { Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface ShowMoreButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  containerClassName?: string;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ onClick, open, containerClassName }) => {
  const showMoreButtonText = open ? 'Show less' : 'Show more';
  const ShowMoreIcon = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <Button
      className={containerClassName}
      onClick={onClick}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {showMoreButtonText}
      <ShowMoreIcon color="primary" sx={{ ml: 1 }} />
    </Button>
  );
};

export default ShowMoreButton;
