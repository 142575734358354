import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createProject } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useCreateProject = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({
      projectName,
      userId,
      eventDescription,
    }: {
      projectName: string;
      userId: string;
      eventDescription: string | null;
    }) => createProject(projectName, userId, eventDescription),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      showSnackbar(Messages.SUCCESS.PROJECT_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateProject;
