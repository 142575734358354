import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  DialogActions,
} from '@mui/material';
import styles from './CreateProjectModal.module.css';
import { useAuth } from '../../../services/auth';
import { useRunSearch } from '../../../services/api/search/hooks';
import { useUserSettingsStore } from '../../../stores';
import { Project } from '../../../services/api/workspace/types';
import { useCreateProject } from '../../../services/api/workspace/projects/hooks';
import { SearchResponse } from '../../../services/api/search/types';
import { defaultStep, totalSteps, getStepInfo } from './CreateProjectModal.utils';

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  isFirstProjectCreation?: boolean;
  projectsLength: number;
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  isOpen,
  onClose,
  isFirstProjectCreation = false,
  projectsLength,
}) => {
  const { setSelectedProjectId, setSelectedSearchId } = useUserSettingsStore();
  const { currentUser } = useAuth();
  const [currentStep, setCurrentStep] = useState<number>(defaultStep);
  const [newProjectName, setNewProjectName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [eventDescription, setEventDescription] = useState('');

  const { mutate: createProject } = useCreateProject();
  const { mutate: runSearch } = useRunSearch();

  const nextButtonDisabled =
    (currentStep === defaultStep && !newProjectName.trim().length) ||
    (currentStep === 2 && !searchQuery.trim().length);

  const resetState = () => {
    setCurrentStep(defaultStep);
    setNewProjectName('');
    setSearchQuery('');
    setEventDescription('');
  };

  const handleBack = () => setCurrentStep((prevStep) => prevStep - 1);
  const handleNext = () => {
    if (currentStep === totalSteps) {
      if (!searchQuery.trim().length) return;

      createProject(
        {
          projectName: newProjectName,
          userId: currentUser?.id || '',
          eventDescription: eventDescription.length ? eventDescription : null,
        },
        {
          onSuccess: (project: Project) => {
            setSelectedProjectId(project.id);
            runSearch(searchQuery, {
              onSuccess: (data: SearchResponse) => {
                setSelectedSearchId(data.search_id);
              },
            });
            onClose();
            resetState();
          },
        },
      );
      return;
    }

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleClose = () => {
    if (!projectsLength) {
      createProject(
        { projectName: 'Untitled event', userId: currentUser?.id || '', eventDescription: null },
        {
          onSuccess: (project: Project) => {
            setSelectedProjectId(project.id);
          },
        },
      );
    }
    onClose();
    resetState();
  };

  const { title, description } = getStepInfo(currentStep, isFirstProjectCreation);
  const inputStyle: React.CSSProperties = { padding: '22px 16px 16px 9px', fontSize: 16 };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle className={styles.title}>
        {isFirstProjectCreation && (
          <Typography mt={2} mb={1}>
            Welcome to Event Producer Pro
          </Typography>
        )}
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={18}>{description}</Typography>
        {currentStep === 1 && (
          <TextField
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Event name"
            variant="standard"
            size="small"
            fullWidth
            InputProps={{
              inputProps: { style: inputStyle },
            }}
          />
        )}
        {currentStep === 2 && (
          <TextField
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="E.g., Sustainable energy, digital transformation, or AI in healthcare.’"
            variant="standard"
            size="small"
            fullWidth
            InputProps={{
              inputProps: { style: inputStyle },
            }}
          />
        )}
        {currentStep === 3 && (
          <>
            <TextField
              rows={3}
              multiline
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
              placeholder="E.g., The event is for senior healthcare professionals and will focus on AI innovations. We aim to attract 300 attendees and feature industry leaders as speakers.’"
              variant="standard"
              size="small"
              fullWidth
              InputProps={{
                inputProps: { style: inputStyle },
              }}
            />
            <Typography color="#00000099" variant="body2">
              Optional
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 24px 24px 24px' }}
      >
        <Typography className={styles.step}>
          Step {currentStep} of {totalSteps}
        </Typography>
        <Box>
          <Button onClick={handleClose}>Close</Button>
          {currentStep > 1 && <Button onClick={handleBack}>Back</Button>}
          <Button onClick={handleNext} variant="contained" disabled={nextButtonDisabled}>
            Next
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectModal;
