import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import DetailsSubtitle from './DetailsSubtitle';
import NoDataLabel from './NoDataLabel';

interface ItemInfoLabelProps {
  title: string;
  value?: string | number;
  skeletonWidth?: number;
  style?: React.CSSProperties;
  loadingData?: boolean;
}

const ItemInfoLabel: React.FC<ItemInfoLabelProps> = ({
  title,
  value,
  style,
  skeletonWidth = 40,
  loadingData = false,
}) => {
  const isValueEmpty = value == undefined;

  return (
    <Box style={style} display="flex" alignItems="center">
      <DetailsSubtitle style={{ marginRight: 8 }}>{title}</DetailsSubtitle>
      {isValueEmpty && loadingData && (
        <Skeleton variant="text" width={skeletonWidth} sx={{ ml: 1 }} />
      )}
      {isValueEmpty && !loadingData && <NoDataLabel />}
      {!isValueEmpty && <Typography variant="body1">{value}</Typography>}
    </Box>
  );
};

export default ItemInfoLabel;
