import React from 'react';
import { TableRow, TableCell, Box, Typography } from '@mui/material';

interface DetailsTableItemsNotFoundProps {
  title: string;
}

const DetailsTableItemsNotFound: React.FC<DetailsTableItemsNotFoundProps> = ({ title }) => {
  return (
    <TableRow>
      <TableCell colSpan={5}>
        <Box display="flex" justifyContent="center" padding={2}>
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default DetailsTableItemsNotFound;
