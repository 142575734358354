import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFolderSponsor } from '../requests';

const useDeleteFolderSponsor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (sponsorId: string) => deleteFolderSponsor(sponsorId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
  });
};

export default useDeleteFolderSponsor;
