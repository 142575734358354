import { Typography } from '@mui/material';
import React from 'react';

interface NoDataLabelProps {
  style?: React.CSSProperties;
}

const NoDataLabel: React.FC<NoDataLabelProps> = ({ style }) => {
  return (
    <Typography style={style} color="#9E9E9E" variant="body2">
      No data
    </Typography>
  );
};

export default NoDataLabel;
