import { Sponsor, SponsorResult } from '../../../services/api/search/types';
import { createTableConfig } from '../../../utils/table/table';

export const sponsorsTableConfig = createTableConfig<Sponsor>({
  exportHeaders: ['Company name', 'Description', 'Competitor events', 'Relevancy score'],
  exportFileName: 'trending_sponsors',
  getExportRows: (sponsors: Sponsor[]) =>
    sponsors.map((sponsor) => [
      sponsor.company_name,
      sponsor.description,
      sponsor.competitor_events.join(', '),
      'No data',
    ]),
  filterByKeyword: (sponsor: Sponsor, keyword: string) =>
    sponsor.company_name.toLowerCase().includes(keyword.toLowerCase()) ||
    sponsor.description.toLowerCase().includes(keyword.toLowerCase()),
});

export const flattenSponsorsExtracts = (sponsors: SponsorResult[]): Sponsor[] => {
  return sponsors.flatMap((item) => item.extracts);
};
