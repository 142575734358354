import { useQuery } from '@tanstack/react-query';
import { Search, SearchResponse } from '../types';
import { fetchSearchesForUser } from '../requests';
import { Status } from '../../../../enums';
import { useUserSettingsStore } from '../../../../stores';

const useFetchSearches = () => {
  const selectedProjectId = useUserSettingsStore((store) => store.selectedProjectId);

  return useQuery({
    queryKey: ['searches'],
    queryFn: () => fetchSearchesForUser(),
    select: (data) =>
      data.map(
        (search: SearchResponse): Search => ({
          id: search.search_id,
          term: search.search_term,
          searchStatus: search.search_status,
          scrapeStatus: search.scrape_status,
          extractionStatus: search.extraction_status,
          createdAt: search.created_at,
        }),
      ),

    refetchInterval: (response) => {
      const searches: SearchResponse[] = response.state.data;
      const allSearchesCompleted = searches.every(
        (search) => search.search_status === Status.COMPLETE,
      );

      if (allSearchesCompleted || !searches.length || !selectedProjectId) {
        return false;
      }

      return 7000;
    },
    initialData: [],
    enabled: !!selectedProjectId,
  });
};

export default useFetchSearches;
