import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useConfirm } from 'material-ui-confirm';
import SearchDetails from '../../components/dashboard/SearchDetails/SearchDetails';
import { SearchCreateResponse } from '../../services/api/search/types';
import { useDeleteSearch, useFetchSearches, useRunSearch } from '../../services/api/search/hooks';
import styles from './Dashboard.module.css';
import SearchHistoryList from '../../components/dashboard/SearchHistoryList/SearchHistoryList';
import CreateProjectModal from '../../components/shared/CreateProjectModal/CreateProjectModal';
import { useFetchProjects } from '../../services/api/workspace/projects/hooks';
import SearchPanel from '../../components/dashboard/SearchPanel/SearchPanel';
import { useUserSettingsStore } from '../../stores';
import { noop } from '../../utils/common/common';

function Dashboard() {
  const [selectedSearchId, setSelectedSearchId] = useUserSettingsStore((store) => [
    store.selectedSearchId,
    store.setSelectedSearchId,
  ]);

  const confirm = useConfirm();

  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const { data: projects, isFetched: projectsFetched } = useFetchProjects({});

  const { data: searchHistory, isLoading } = useFetchSearches();
  const { mutate: deleteSearch } = useDeleteSearch();

  const { mutate: runSearch } = useRunSearch();

  useEffect(() => {
    if (projectsFetched && !projects.length) {
      setCreateProjectModalOpen(true);
    }
  }, [projects.length, projectsFetched]);

  const handleSearch = (searchTerm: string) => {
    runSearch(searchTerm, {
      onSuccess: (data: SearchCreateResponse) => setSelectedSearchId(data.search_id),
    });
  };

  const handleSearchButtonClick = () => setSelectedSearchId('');

  const handleDeleteSearch = (searchId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    confirm({
      title: 'Delete search',
      description: 'Are you sure you want to delete this search?',
    })
      .then(() =>
        deleteSearch(searchId, {
          onSuccess: () => {
            if (selectedSearchId === searchId) {
              setSelectedSearchId('');
            }
          },
        }),
      )
      .catch(noop);
  };

  return (
    <Box className={styles.container}>
      <Drawer variant="permanent" className={styles.drawer} classes={{ paper: styles.drawerPaper }}>
        <Button
          onClick={handleSearchButtonClick}
          startIcon={<SearchIcon />}
          variant="outlined"
          className={styles.startButton}
        >
          NEW SEARCH
        </Button>

        {searchHistory.length > 0 && (
          <Typography variant="h6" className={styles.title}>
            Recent searches
          </Typography>
        )}

        <Box className={styles.searchHistory}>
          <SearchHistoryList
            isLoading={isLoading}
            searchHistory={searchHistory}
            selectedSearchId={selectedSearchId}
            setSelectedSearchId={setSelectedSearchId}
            handleDeleteSearch={handleDeleteSearch}
          />
        </Box>
      </Drawer>
      <Box className={styles.mainContent}>
        {selectedSearchId ? (
          <SearchDetails searchId={selectedSearchId} />
        ) : (
          <SearchPanel handleSearch={handleSearch} />
        )}
      </Box>
      <CreateProjectModal
        isFirstProjectCreation
        isOpen={createProjectModalOpen}
        onClose={() => setCreateProjectModalOpen(false)}
        projectsLength={projects.length}
      />
    </Box>
  );
}

export default Dashboard;
