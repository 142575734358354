import { Person, PersonResult } from '../../../services/api/search/types';
import { createTableConfig } from '../../../utils/table/table';

export const peopleTableConfig = createTableConfig<Person>({
  exportHeaders: ['Name', 'Position'],
  exportFileName: 'trending_people',
  getExportRows: (people: Person[]) => people.map((person) => [person.full_name, person.reasoning]),
  filterByKeyword: (person: Person, keyword: string) =>
    person.full_name.toLowerCase().includes(keyword.toLowerCase()) ||
    person.reasoning.toLowerCase().includes(keyword.toLowerCase()),
});

export const flattenPeopleExtracts = (people: PersonResult[]): Person[] => {
  return people.flatMap((item) => item.extracts);
};
