import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNoteFromSponsorExtract } from '../requests';

const useDeleteNoteFromSponsorExtract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ noteId, sponsorExtractId }: { noteId: string; sponsorExtractId: string }) =>
      deleteNoteFromSponsorExtract(noteId, sponsorExtractId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
    },
  });
};

export default useDeleteNoteFromSponsorExtract;
