import { Typography } from '@mui/material';
import React from 'react';

interface DetailsSubtitleProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const DetailsSubtitle: React.FC<DetailsSubtitleProps> = ({ children, style }) => {
  return (
    <Typography style={style} fontWeight="700" variant="body2" color="textSecondary">
      {children}
    </Typography>
  );
};

export default DetailsSubtitle;
