import * as XLSX from 'xlsx';

export const exportToExcel = (filename: string, headers: string[], rows: string[][]) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const exportToCsv = (filename: string, headers: string[], rows: string[][]) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  csvContent += `${headers.join(',')}\n`;

  rows.forEach((row) => {
    const rowContent = row.map((cell) => `"${cell.replace(/"/g, '""')}"`).join(',');
    csvContent += `${rowContent}\n`;
  });
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
