import React, { useMemo, useState } from 'react';
import { Menu, Box, IconButton, Button, Typography, Checkbox } from '@mui/material';
import TagIndicator from '../TagIndicator/TagIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TagCustomizer from '../TagCustomizer/TagCustomizer';
import { DotColors } from '../../../constants/tagColorPalette';
import useFetchTags from '../../../services/api/workspace/tags/hooks/useFetchTags';
import { FolderTag, Tag } from '../../../services/api/workspace/types';
import {
  useCreateTag,
  useDeleteTag,
  useUpdateTag,
} from '../../../services/api/workspace/tags/hooks';
import styles from './TagManagementMenu.module.css';
import { MappedTag, useMappedTags } from './TagManagementMenu.utils';

interface TagManagementMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  manageTags: (idsToAdd: string[], idsToRemove: string[]) => void;
  initialSelectedTags: FolderTag[];
}

type MenuMode = 'view' | 'add' | 'edit';

export type TagType = {
  color: string;
  name: string;
};

const TagManagementMenu: React.FC<TagManagementMenuProps> = ({
  anchorEl,
  open,
  onClose,
  manageTags,
  initialSelectedTags,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>('view');
  const [editableTag, setEditableTag] = useState<Tag | null>(null);

  const { data: tags } = useFetchTags({});
  const { mutate: createTag } = useCreateTag();
  const { mutate: updateTag } = useUpdateTag();
  const { mutate: deleteTag } = useDeleteTag();

  const [mappedTags, setMappedTags] = useMappedTags(tags, initialSelectedTags, open);

  const handleAddClick = () => {
    setMenuMode('add');
  };

  const handleEditClick = (tag: Tag) => {
    setEditableTag(tag);
    setMenuMode('edit');
  };

  const handleBack = () => {
    setMenuMode('view');
    setEditableTag(null);
  };

  const handleSave = (tag: TagType, id: string | null) => {
    if (menuMode === 'add') {
      createTag(tag);
    } else if (menuMode === 'edit' && editableTag) {
      updateTag({
        tagId: id || '',
        payload: { color: tag.color, name: tag.name },
      });
    }
    handleBack();
  };

  const handleDelete = (id: string) => {
    deleteTag(id);
  };

  const handleTagSelect = (tagId: string) => {
    setMappedTags((prev: MappedTag[]) =>
      prev.map((tag) => {
        return tag.id === tagId ? { ...tag, selected: !tag.selected } : tag;
      }),
    );
  };

  const handleSaveTags = () => {
    const tagsToAdd = mappedTags
      .filter((tag) => tag.selected && tag.selected !== tag.initialSelected)
      .map((tag) => tag.id);
    const tagsToRemove = mappedTags
      .filter((tag) => !tag.selected && tag.selected !== tag.initialSelected)
      .map((tag) => tag.id);
    manageTags(tagsToAdd, tagsToRemove);
    setMappedTags([]);
    onClose();
  };

  const handleClose = () => {
    setMappedTags([]);
    onClose();
  };

  const hasUpdatedTagSelection = useMemo(
    () => mappedTags.some((tag) => tag.initialSelected !== tag.selected),
    [mappedTags],
  );

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose} autoFocus={false}>
      <Box className={styles.container}>
        {menuMode === 'view' ? (
          <>
            {mappedTags.map((tag) => (
              <Box className={styles.tagRow} key={tag.id}>
                <Box onClick={() => handleTagSelect(tag.id)} className={styles.tag}>
                  <Checkbox checked={tag.selected} onChange={() => {}} color="primary" />
                  <Box style={{ width: 160 }}>
                    <TagIndicator
                      backgroundColor={tag.color}
                      dotColor={DotColors[tag.color]}
                      label={tag.name}
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                </Box>

                <IconButton onClick={() => handleEditClick(tag)}>
                  <EditIcon color="disabled" />
                </IconButton>
                <IconButton onClick={() => handleDelete(tag.id)}>
                  <DeleteIcon color="disabled" />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              color="primary"
              onClick={handleAddClick}
              className={styles.addTagButton}
            >
              <Typography variant="body2">ADD TAG</Typography>
            </Button>
            <Button disabled={!hasUpdatedTagSelection} onClick={handleSaveTags} variant="contained">
              Save
            </Button>
          </>
        ) : (
          <TagCustomizer
            initialTag={editableTag}
            onSave={handleSave}
            onClose={onClose}
            onBack={handleBack}
          />
        )}
      </Box>
    </Menu>
  );
};

export default TagManagementMenu;
